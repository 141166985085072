
import { defineComponent, onMounted, reactive, toRefs, watch } from 'vue'
import { getHotList } from '@/apis/home'
import { HotItem } from '@/model/homeModel'
import { useStore } from '@/store'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  setup() {
    const { t } = useI18n()
    const store = useStore()
    const router = useRouter()
    const dataMap = reactive({
      listQuery: {
        language: store.state.app.language
      },
      list: Array<HotItem>(),
      timer: -1
    })
    const methods = reactive({
      async getList() {
        const resource = store.state.app.device === 1 ? 'mobi' : 'web'
        const res = await getHotList(dataMap.listQuery)
        const list: HotItem[] = (res?.data[resource] ?? [])
          .map(v => ({ ...v, url: `${store.state.app.config.resource}/${v.url}` }))
        dataMap.list = list
        window.sessionStorage.hotIds = JSON.stringify(list.map(v => v.link))
      },
      onClick(id: string) {
        if (id !== '') router.push(`/hotDetail?id=${dataMap.list.findIndex(v => v.link === id)}`)
      }
    })

    onMounted(() => {
      dataMap.timer && window.clearInterval(dataMap.timer)
      dataMap.timer = window.setInterval(() => {
        if ('hotShowCase' in window) {
          (window as any).hotShowCase()
          window.clearInterval(dataMap.timer)
          window.setTimeout(() => {
            const el = window.document.querySelector('.J_Hot') as any
            if (el) el.style.visibility = 'visible'
          }, 300)
        }
      }, 300)
      const el = window.document.querySelector('.J_Hot')
      if (store.state.app.device === 1 && el) {
        el.classList.add('m-hot')
      }
      methods.getList()
    })

    watch(() => store.state.app.language, (lang) => {
      dataMap.listQuery.language = lang
      methods.getList()
    })

    return {
      t,
      ...toRefs(dataMap),
      ...toRefs(methods)
    }
  }
})
