
import { computed, defineComponent, onMounted, reactive, ref, toRefs, watch } from 'vue'
import logo from '@/assets/images/fg_logo.png'
import { useStore } from '@/store'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  setup() {
    const { t } = useI18n()
    const store = useStore()
    const cardsRef = ref(null)
    const imageList = computed(() => {
      const list = []
      for (let i = 1; i <= 27; i++) {
        // eslint-disable-next-line
        list.push(require(`@/assets/images/big_cards/fg_big_puke_${i}.png`))
      }
      return list
    })
    const dataMap = reactive({
      slogonImage: ''
    })

    onMounted(() => {
      const el = window.document.querySelector('.J_Explain')
      if (store.state.app.device === 1 && el) {
        el.classList.add('m-explain')
      }

      const cards = [...(cardsRef.value as any).children]
      let index = 0
      window.setInterval(() => {
        cards.map((v: HTMLElement, i: number) => {
          v.style.visibility = index === i ? 'visible' : 'hidden'
        })
        index += 1
        if (index >= 27) index = 0
      }, 150)
    })

    watch(() => store.state.app.config.resource, (n, o) => {
      if (n && n !== o) {
        dataMap.slogonImage = `${n}/bimg/${store.state.app.language}/slogon.png`
      }
    }, { immediate: true })

    watch(() => store.state.app.language, (n, o) => {
      if (n && n !== o && store.state.app.config.resource) {
        dataMap.slogonImage = `${store.state.app.config.resource}/bimg/${n}/slogon.png`
      }
    }, { immediate: true })

    return {
      t,
      logo,
      cardsRef,
      imageList,
      ...toRefs(dataMap)
    }
  }
})
