/*
 * @Description: 首页
 */
import https from '@/utils/https'
import { RootObject } from '@/model/rootObject'
import { ContentType, Method } from 'axios-mapper'
import { CarouselItem, HotItem } from '@/model/homeModel'
import { Carousel, Hot } from '@/model/home'

// 获取轮播图
export const getCarouselList = (params: any) => {
  return https().request<RootObject<Carousel<CarouselItem>>>('api/get_carousel', Method.GET, params, ContentType.form)
}

// 获取热门游戏
export const getHotList = (params: any) => {
  return https().request<RootObject<Hot<HotItem>>>('api/get_hot_games', Method.GET, params, ContentType.form)
}

// 提交留言
export const postMessage = (params: object) => {
  return https().request<RootObject<object>>('api/msgBoard/message', Method.POST, params, ContentType.json)
}
