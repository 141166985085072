
import { computed, defineComponent, onMounted, reactive, ref, toRefs } from 'vue'
import { useStore } from '@/store'
import { ElForm, ElMessage } from 'element-plus'
import { useI18n } from 'vue-i18n'
import { postMessage } from '@/apis/home'
import md5 from 'blueimp-md5'

export default defineComponent({
  setup() {
    const { t } = useI18n()
    const store = useStore()
    const contact = computed(() => store.state.app.config.contactUs)
    const formRef = ref(ElForm)
    const dataMap = reactive({
      form: {
        name: '',
        phone: '',
        email: '',
        information: '',
        uuid: '',
        sign: ''
      },
      rules: {
        name: [{
          required: true,
          validator: (_: any, value: string, cb: (n?: Error) => void) => {
            if (!value) cb(new Error(t('home.nameTips')))
            else cb()
          },
          trigger: ['blur', 'change']
        }],
        email: [{
          required: true,
          validator: (_: any, value: string, cb: (n?: Error) => void) => {
            if (!value) cb(new Error(t('home.emailTips1')))
            else if (!/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value)) cb(new Error(t('home.emailTips2')))
            else cb()
          },
          trigger: ['blur', 'change']
        }],
        phone: [{
          required: false,
          validator: (_: any, value: string|number, cb: (n?: Error) => void) => {
            if (!/^[0-9]*$/.test(String(value))) cb(new Error(t('home.phoneTips')))
            else cb()
          },
          trigger: ['blur', 'change']
        }]
      }
    })
    const methods = reactive({
      onSubmit() {
        formRef.value.validate(async(valid: boolean) => {
          if (valid) {
            const uuid = String(Date.parse(Date()))
            if (window.localStorage.uuid) {
              dataMap.form.uuid = window.localStorage.uuid
            } else {
              dataMap.form.uuid = uuid
              window.localStorage.uuid = uuid
            }
            if (window.localStorage.sign) {
              dataMap.form.sign = window.localStorage.sign
            } else {
              const sign = md5(`${window.navigator.userAgent}${uuid.substring(0, 10)}`)
              dataMap.form.sign = sign
              window.localStorage.sign = sign
            }
            const res = await postMessage(dataMap.form)
            if (res?.code === 0) {
              ElMessage.success(t('home.submitSuccess'))
              formRef.value.resetFields()
            }
          }
        })
      }
    })

    onMounted(() => {
      const el = window.document.querySelector('.J_Contact')
      if (store.state.app.device === 1 && el) {
        el.classList.add('m-contact')
      }
    })

    return {
      t,
      contact,
      formRef,
      ...toRefs(methods),
      ...toRefs(dataMap)
    }
  }
})
