import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c522ae22"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home J_Home" }
const _hoisted_2 = { class: "hot-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Carousel = _resolveComponent("Carousel")!
  const _component_Magician = _resolveComponent("Magician")!
  const _component_Hot = _resolveComponent("Hot")!
  const _component_Explain = _resolveComponent("Explain")!
  const _component_Advantage = _resolveComponent("Advantage")!
  const _component_Contact = _resolveComponent("Contact")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Carousel),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Magician),
      _createVNode(_component_Hot)
    ]),
    _createVNode(_component_Explain),
    _createVNode(_component_Advantage),
    _createVNode(_component_Contact),
    _createElementVNode("div", {
      class: "J_SVGA svg-container",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
    })
  ]))
}