
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper'
import { defineComponent, onMounted, reactive, toRefs, watch } from 'vue'
import { getCarouselList } from '@/apis/home'
import { CarouselItem } from '@/model/homeModel'
import { useStore } from '@/store'
import bannerBottom from '@/assets/images/bannerbottom.png'
import bannerTop from '@/assets/images/bannertop.png'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide
  },
  setup() {
    SwiperCore.use([Autoplay, Pagination, Navigation])

    const renderBullet = (_: number, className: string) => {
      return `<span class="${className}"></span>`
    }
    const store = useStore()
    const router = useRouter()
    const dataMap = reactive({
      listQuery: {
        language: store.state.app.language
      },
      list: Array<CarouselItem>()
    })
    const methods = reactive({
      async getList() {
        const carousel = window.document.querySelector('.J_Carousel')
        const resource = store.state.app.device === 1 ? 'mobi' : 'web'
        const res = await getCarouselList(dataMap.listQuery)
        const list: CarouselItem[] = (res?.data[resource] ?? []).map(v => ({ ...v, url: `${store.state.app.config.resource}/${v.url}` }))
        dataMap.list = list

        if (!list.length && carousel) {
          (carousel.querySelector('.swiper-wrapper') as HTMLElement).style.height = '2rem'
        }
      },
      onClick(item: CarouselItem) {
        if (item.link !== '') {
          router.push(`/carouselDetail?id=${item.link}`)
          window.sessionStorage.menu = ''
          const el = window.document.querySelector('.J_Menu-item li[data-key=Home]')
          if (el) el.classList.remove('active')
        }
      }
    })

    onMounted(() => {
      const carousel = window.document.querySelector('.J_Carousel')
      const yun = window.document.querySelector('.J_Yun')
      if (store.state.app.device === 1 && carousel && yun) {
        carousel.classList.add('m-carousel')
        yun.classList.add('m-yun')
      }
      methods.getList()
    })

    watch(() => store.state.app.language, (lang) => {
      dataMap.listQuery.language = lang
      methods.getList()
    })

    return {
      ...toRefs(dataMap),
      ...toRefs(methods),
      bannerTop,
      bannerBottom,
      renderBullet
    }
  }
})
